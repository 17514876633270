import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const RiskPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head title={'Risk Advice'} />
      <header id="header" className="fixed-top">
        <HeaderComponent />
      </header>
      <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Risk and Investment</h2>
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Risk and Investment</li>
                    </ol>
                </div>
            </div>
        </section>
        <section className="about" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 pt-4 pt-lg-0">
                        <h3>How we cosnider risk</h3>
                        <p>
                        Whenever making investments, there is always a certain amount of risk involved. At Kvotum we acknowledge the risk and also encourage participating investors to do their due diligence in considering the project they are investing in as well as of the risk involved.
                        </p>
                        <p>
                        Though Kvotum does everything to carefully select your investment options, by participating in a lending project, your capital is inherently put at risk. You must be prepared for the possibility that you may not receive your money back partially or even in full.
                        </p>
                        <p>
                        When lending money the largest risk is formed by the ability of the loan recipient to repay the loan and/or to make the corresponding monthly payments. In order to reduce the risk, Kvotum is taking multiple precautions. That being said, it is important to note that they do not mitigate them entirely.
                        </p>
                        <p>
                        Please read through the risk factors of investing to be better acquainted with the associated risks:
                        </p>
                        <ul style={{listStyle: 'disc', paddingLeft: '3em'}}>
                        <li>
                            Your money is at risk and you must be prepared for the possibility of not getting it back. This depends on the loan recipient's abilities and diligence in repaying their loan, as well as other factors beyond human control, such as a reduction in value on the corresponding business market or the world economy in general. As a result, Kvotum is not able to guarantee the payback of any of the claims/loans taken/invested in through the database.
                        </li>
                        <li>
                            Your money is not protected by the government. There are no formal protections in places at a policy level to protect your money or your investment.
                        </li>
                        <li>
                            Real estate and Business loans investments are barely liquid. Once the money is invested, it is not easy to take it out immediately. As a result, you must be prepared to be separated from the funds for a potentially long period of time, if not indefinitely in the case of a borrower’s inability to repay the loan you invested in.                
                        </li>
                        <li>
                            Tax is your responsibility. Kvotum does not take responsibility for any tax responsibilities that may apply to you. It is your responsibility to understand the tax policy in your jurisdiction and act accordingly.                
                        </li>
                        <li>
                            Kvotum and its affiliates do not provide any advice or recommendation in relation to any investments made available in the database. If you have any doubts about the risks of such investments or you otherwise require financial advice, you should seek advice or recommendations that are personal to your circumstances from a suitably qualified financial adviser elsewhere. Though there are many risks involved in investing, Kvotum is taking all the precautions to make the process more reliable.
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
      </footer>
    </>
  )
}

export default RiskPage